import React from "react";

function Leadership({ name, bio, position, img_src }) {
  return (
    <section className="relative block py-24 lg:pt-0 bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
              <div className="flex-auto p-5 lg:p-10">
                <div className="flex flex-nowrap mb-4 items-center justify-around">
                  <img
                    className="lg:w-4/12 smallImg rounded-full"
                    src={img_src}
                    alt={name + " headshot"}
                  />
                  <div className="flex-grow pl-10">
                    <h4 className="text-3xl font-bold">{name}</h4>
                    <p className="text-lg font-semibold leading-relaxed mt-1 mb-4 text-gray-600">
                      {position}
                    </p>
                  </div>
                </div>
                <p
                  className="text-base leading-relaxed mt-1 mb-4 text-gray-600"
                  dangerouslySetInnerHTML={{ __html: bio }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Leadership;
